<template>
  <div>
    <main-title :name="name"></main-title>
    <v-row class="about-patch-one">
      <v-col cols="12" md="7" class="offset-md-5 pa-12 content-holder">
        <v-container>
          <h3 class="white--text text-h3 font-weight-bold mb-4">
            Welcome to Yorkshire Bath Resurfacing
          </h3>

          <p class="body-1 white--text">
            Over the years Yorkshire Bath Resurfacing have developed a unique system of resurfacing most type’s of bath
            in-situ, irrespective of age or condition.
          </p>
          <p class="body-1 white--text">
            We use an extremely tough, durable and hard wearing enamel for cast iron and steel baths, basins, pedestals
            and shower trays etc., either in traditional white or by making use of our professional colour matching service.
            Simply supply a sample of the colour and we will match it exactly.
          </p>
          <p class="body-1 white--text">
            Our specialist colour matching department can reproduce all the latest range of bathroom colours or match
            obsolete colours from yesteryear. Alternatively, we can transform your coloured bath to traditional White.
          </p>
          <p class="body-1 white--text">
            After many years of invaluable service, your old bath may now be really showing it’s age, impossible to
            clean, stained, rusty, or even chipped. Yorkshire Bath Resurfacing can return your classic cast iron bath to
            it’s former glory with a beautiful new smooth enamel finish.
          </p>
          <p class="body-1 white--text">
            Our bath re-enamelling is fully guaranteed and carried out by our own specialist, trained employees –
            we do not use franchised workers.
          </p>

          <p class="body-1 white--text">
            All bath resurfacing work is carried out in-situ, with no disruption to the bathroom in around 2-4 hours.
            A resurfaced bath should last between 7-10 years.
          </p>
          <p class="body-1 white--text">
            If you have an enquiry, please click here or contact us on the above telephone numbers.
          </p>
          <p class="body-1 white--text">
            Please click our Frequently Asked Questions section if you need any further information.
            <strong>
              Bath Resurfacing now covers London and surrounding areas!
            </strong>
          </p>
        </v-container>

      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-container class="pt-12 pb-12 mb-12">
          <v-card flat >
            <v-card-title class="text-center justify-center py-6">
              <h1 class="font-weight-bold text-h2 basil--text mb-6">
                Information
              </h1>
            </v-card-title>

            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
            >
              <v-tab
                  v-for="item in items"
                  :key="item"
              >
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                  v-for="item in items"
                  :key="item"
              >
                <v-card
                    color="basil"
                    flat
                    max-width="900"
                    class="pt-12 pb-12 ma-auto"
                >
                  <v-card-text>

                    <div v-if="tab === 0">
                      <h3 class="text-h3 mb-10">
                        Bath and Sink Problems
                      </h3>

                      <div v-for="i in bath_items" class="mb-12">
                        <h5 class="text-h5 font-weight-bold mb-2">
                          {{ i.title }}
                        </h5>
                        <p v-for="t in i.text" class="body-1">
                          {{ t }}
                        </p>
                      </div>

                    </div>
                    <div v-if="tab === 1">
                      <h3 class="text-h3 mb-12">
                        About our Bath Repair Service
                      </h3>
                      <p class="body-1">Over the years Yorkshire Bath Resurfacing has developed a unique system of resurfacing most
                        type’s of bath in-situ, irrespective of age or condition.</p>
                      <p class="body-1">We use an extremely tough, durable and hard wearing material for cast iron and pressed steel
                        baths, basins, pedestals, shower trays, Belfast sinks, Aga ranges and more.</p>
                      <p class="body-1">Also we repair / resurface either in traditional white or by making use of our professional
                        colour matching service.</p>
                      <p class="body-1">Our specialist colour matching department can reproduce all the latest range of
                        bathroom colours or match obsolete colours from yesteryear. Alternatively, we can
                        transform your coloured bath to traditional white.</p>
                      <p class="body-1">After many years of invaluable service, your old bath may now be really showing it’s age,
                        impossible to clean, stained, rusty or even chipped. Yorkshire Bath Resurfacing can return
                        your classic cast iron bath with a beautiful, smooth enamel like finish.</p>
                      <p class="body-1">If you have an inquiry, please click here or contact us via the contact page.</p>
                      <p class="body-1">Please click our Frequently Asked Questions section for further information.</p>
                    </div>
                    <div v-if="tab === 2">
                      <h3 class="text-h3 mb-12">
                        Bathroom Services
                      </h3>
                      <p>Yorkshire Bath Resurfacing can effectively repair or restore cast iron, pressed steel
                        and ceramic ware with no disruption to existing decor, at a fraction of the cost of
                        replacing your old bath / sink.</p>
                      <h3 class="mb-5 title">List of Repairs Resurfacing</h3>
                      <ul class="cncn mb-5">
                        <li>Cast Iron Baths</li>
                        <li>Sinks</li>
                        <li>Belfast Sinks</li>
                        <li>Pressed Steel Baths</li>
                        <li>Basins</li>
                        <li>Ceramic Wares</li>
                        <li>Stone resin Wares</li>
                        <li>Agas</li>
                      </ul>
                      <h3 class="mb-5 title">Services Chip Repairs</h3>
                      <ul class="cncn mb-5">
                        <li>Chips</li>
                        <li>Cracks</li>
                        <li>Hairline Cracks</li>
                      </ul>
                      <p>For more detailed information visit all sections on the about section, else visit
                        our site map located here.</p>
                    </div>
                    <div v-if="tab === 3">
                      <h3 class="text-h3 mb-4">
                        Locations We Cover
                      </h3>
                      <p>Yorkshire Bath covers the following areas and 30 miles surrounding each of the areas.
                        A maps icon will be updated with all correct information sometime within the next two
                        weeks. If your not sure if we cover your area please call us
                        <strong>on 01653 531192</strong>.</p>

                      <ul class="areasc">
                        <li>Wakefield</li>
                        <li>Leeds</li>
                        <li>Sheffield</li>
                        <li>Barnsley</li>
                        <li>Doncaster</li>
                        <li>Hull</li>
                        <li>Grimsby</li>
                        <li>Lincoln</li>
                        <li>Chesterfield</li>
                        <li>Mansfield</li>
                      </ul>

                      <ul class="areasc">
                        <li>Hornsea</li>
                        <li>Bridlington</li>
                        <li>Scarborough</li>
                        <li>Middlesbrough</li>
                        <li>Darlington</li>
                        <li>Hartlepool</li>
                      </ul>

                      <ul class="areasc">
                        <li>York</li>
                        <li>Malton</li>
                        <li>Ripon</li>
                      </ul>

                      <ul class="areasc">
                        <li>Whitby</li>
                        <li>Redcar</li>
                      </ul>
                    </div>
                    <div v-if="tab === 4">
                      <h3 class="text-h3 mb-12">
                        Our References
                      </h3>

                      <ul class="xlxl mb-2">
                        <li><a target="_blank"
                               href="https://www.castlehoward.co.uk/">Castle Howard</a></li>
                        <li><a target="_blank"  href="https://www.yorkshirebathresurfacing.co.uk/reputation/www.hilton.co.uk/blackpool">Blackpool Hilton</a></li>
                        <li><a target="_blank"  href="https://www.yorkshirebathresurfacing.co.uk/reputation/www.bw-forestandvalehotel.co.uk/">Forest and Vale</a></li>
                        <li><a target="_blank"  href="https://www.morrisonplc.com/">Morrisons PLC</a></li>
                      </ul>

                      <ul class="xlxl mb-2">
                        <li><a target="_blank"  href="https://www.kier.co.uk/">Kier</a></li>
                        <li><a target="_blank"  href="https://www.mcaleer-rushe.co.uk/">Mcaleer Rushe</a></li>
                        <li><a target="_blank"  href="https://www.chatsworth.org/">Chatsworth Manor</a></li>
                        <li><a target="_blank"  href="https://www.yorkshirebathresurfacing.co.uk/reputation/qhotels.co.uk/AldwarkManorHotel">Alwalk Manor</a></li>
                      </ul>

                      <ul class="xlxl mb-2">
                        <li><a target="_blank"  href="https://www.yorkshirebathresurfacing.co.uk/reputation/www.hilton.co.uk/glasgow">Glasgow Hilton</a></li>
                        <li><a target="_blank"  href="https://www.royalhotelyork.co.uk/">Royal York</a></li>
                        <li><a target="_blank"  href="https://www.metropole-hotel.co.uk/">Metropole Hotel</a></li>
                        <li><a target="_blank"  href="https://www.principal-hayley.com/venues--hotels/kenwood-hall.aspx">Kenwood Park</a></li>
                      </ul>

                      <ul class="xlxl mb-2">
                        <li><a target="_blank"  href="https://www.tph.org.uk/?page=phhome">Pennine Housing</a></li>
                        <li><a target="_blank"  href="https://www.redbridge.gov.uk/">Redbridge Borough Council</a></li>
                        <li><a target="_blank"  href="https://www.mansfield.gov.uk/index.aspx?articleid=1">Mansfield District Council</a></li>
                        <li><a target="_blank"  href="https://www.wdh.co.uk/Pages/Default.aspx">Wakefield District Housing</a></li>
                      </ul>

                      <p class="body-1 mt-5">This is just a few of our ever growing list, please contact us for more information</p>

                    </div>
                    <div v-if="tab === 5">
                      <h3 class="text-h4 mb-12">
                        Frequently Asked Questions Bathroom Services
                      </h3>

                      <ul class="q_and_a">
                        <li v-for="question in questions" class="mb-10 pb-4">
                          <p class="title">
                            {{ question.question }}
                          </p>
                          <p class="body-1">
                            {{ question.answer }}
                          </p>
                        </li>
                      </ul>

                      <p class="body-1 font-weight-bold">
                        Further information is available by contacting our main sales office on:
                        <br>
                        <a taget="_blank"  href="tel:01653531192">01653 531192</a> or visiting our website at www.yorkshirebathresurfacing.co.uk
                        <br>
                        PLEASE NOTE: This information sheet should be used as a guide only.
                      </p>
                    </div>
                    <div v-if="tab === 6">
                      <h3 class="text-h3 mb-12">
                        Changing the Colour
                      </h3>

                      <p class="body-1">
                        Changing the colour of your bath is an option you may be considering. Over the years
                        we have carried out 100s of colour changes for customers. We currently have over 1000
                        colour swatches in stock.
                      </p>
                      <p class="body-1">
                        We will arrange a visit from one of our technicians who will work with you in selecting a
                        colour that nearest matches your requirements.
                      </p>
                      <p class="body-1">
                        We will then send the swatch away to be expertly matched. (please note that this can take up to
                        14 days from us sending the colour away to resurfacing your bath)
                      </p>
                      <p class="body-1">
                        If you would like to change the colour to white, there will be no extra waiting time as we
                        always keep white enamel in stock
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>

      </v-col>
    </v-row>

  </div>
</template>
<style lang="scss">
  .about-patch-one {
    background: url('/images/jared-rice-PibraWHb4h8-unsplash.jpg') center center;
    background-size: cover;
    background-attachment: fixed;
  }
  .content-holder {
    background: rgba(0,0,0,0.6);
  }
</style>
<script>
  export default {
    data: () => ({
      name: 'About Us',
      tab: null,
      items: [
        'Cleaning',
        'Bath Repair',
        'Our Services',
        'Locations',
        'Clients',
        'FAQ',
        'Colour Change',
      ],
      bath_items: [
        {
          title:'Cleaning Products',
          text: [
              'Products that contain any form of bleach will strip away existing enamel on the bath and causes stains and rough patches which are impossible to clean. Before using a cleaning product on enamel surfaces make sure you read the small print on the back. All products should indicate if they are safe for enamel or not.',
          ]
        },
        {
          title:'Rust',
          text: [
            'Rust can eventually burrow it’s way under enamel surfaces causing decay. While resurfacing your bath we will treat any rusted areas accordingly and ensure rust problems do not re-occur.',
          ]
        },
        {
          title:'Limescale',
          text: [
            'Have you noticed green spots / lines appearing in your bath / sink ? This is caused by limescale unfortunately limescale will always be a problem. Limescale usually only starts to show after many years of use. However if you have a leaking tap it will quicken the process. In the preparation stage of resurfacing any limescale currently on a bath / sink will be removed and our technician will advise you on the best methods of keeping the problem of limescale returning to a minimum.',
          ]
        },
        {
          title:'Leaking taps',
          text: [
            'A leaking tap will eventually erode your enamel surface. We’d recommend calling a plumber and getting all leaks fixed.',
          ]
        },
        {
          title:'Mistakes',
          text: [
            'People make mistakes it’s a fact of life, if you accidentally drop a heavy / sharp object into your bath / sink it will chip and will need either a chip repair or resurface depending on the size and depth of the chip.',
          ]
        },
        {
          title:'Remember, before booking for your bath resurfacing you will need to do the following:',
          text: [
            'At the very minimum Yorkshire Bath Resurfacing would require that all tiling around the area of the bath / sink before we commence work resurfacing and that the bathroom is not in a building site condition ie, dust and debris.',
            'Whilst 95% of baths are resurfaced with all the fittings in place we can resurface and repair baths / sinks without any fittings. However we would require access to clean water as the bath is copiously washed in the preparation stage.',
          ]
        },
      ],
      questions: [
        {
          question: 'Q: What type of baths can be resurfaced?',
          answer: 'At Yorkshire Bath Resurfacing we resurface Cast Iron or Pressed Steel baths.'
        },
        {
          question: 'Q: My bath is in a very poor condition the surface is badly chipped, worn away in places, rusty and quite rough to the touch, how will it look and feel after resurfacing?',
          answer: 'As near as possible to a new cast / pressed steel bath, all chipped, rusty and worn away areas will be repaired / treated prior to resurfacing. Please note that certain manufacturer characteristics in the original bath surface such as a degree of surface contour, miniscule black specks (Pinholes) may still be evident in certain baths, these are in the original cast and in no way detrimental to the use or the service life of the resurfaced bath.'
        },
        {
          question: 'Q: Will my bath have to be removed for the re-enamelling process?',
          answer: 'No, we come to you and the bath is resurfaced in the bathroom (Please Note: not the garage or outbuildings!!)'
        },
        {
          question: 'Q: How long will it take to resurface my bath & how soon can I use my bath after resurfacing?',
          answer: 'It can take up to half a day for a standard bath and your bath is back in use after only 48 hours.'
        },
        {
          question: 'Q: Are there any solvent smells or odours associated with the resurfacing?',
          answer: 'We seal off the bathroom and use an extractor whilst the work is being carried out so very little solvent odour escapes from the bathroom.'
        },
        {
          question: 'Q: My bathroom has no opening window is this a problem?',
          answer: 'It is not always possible to carry out resurfacing works if there is no opening window, please contact the office for more information.'
        },
        {
          question: 'Q: My bathroom has a feature floor (i.e. exposed wood / slate etc.) is this a problem?',
          answer: 'No, but you must inform us before the day of your appointment.'
        },
        {
          question: 'Q: Do my Chrome bath taps, waste and overflow have to be removed?',
          answer: 'No'
        },
        {
          question: 'Q: Do my Gold finish bath taps, waste and overflow have to be removed?',
          answer: 'Yes, if they have been fitted in approximately the last 2 years. Further information can be obtained from our office.'
        },
        {
          question: 'Q: I have just redecorated my bathroom will your operator take care when resurfacing my bath?',
          answer: 'Yes, all tiles, decor and carpets will be protected during the Resurfacing process.'
        },
        {
          question: 'Q: I am re-tiling my bathroom, should I have my bath resurfaced before or after?',
          answer: 'After is best, as we won’t damage your new tiles. But we would advise that the final mastic sealant around the edge of the bath is applied after resurfacing.'
        },
        {
          question: 'Q: I am re-designing my bathroom involving substantial building work e.g. plastering, new floor, moving of WC & wash hand basin, new window, door etc. Should I have my bath resurfaced before during or after this work?',
          answer: 'Definitely after as successful bath resurfacing cannot be achieved in building site conditions and our operators are instructed not to commence work if these conditions apply.'
        },
        {
          question: 'Q: How do I clean and maintain a resurfaced bath?',
          answer: 'The same as a new cast iron bath. We recommend you clean it with a non-scratch cream cleaner or bathroom mousse (always follow manufactures instructions). Do not allow your bath taps to drip, remove shower mat after use (if used) and always remove any excess standing water.'
        },
        {
          question: 'Q: How long does a resurfaced bath last?',
          answer: 'With the correct aftercare your resurfaced bath will last for many years and one of the great things about resurfacing is that when it eventually starts to look worn it can be resurfaced again.'
        },
        {
          question: 'Q: Is there anything I should do prior to your Operators arrival?',
          answer: 'Yes, we require all shower screens and plastic soap bars to be removed and the wall behind soap bars to be in good order, if you are unable to do this yourself, you may request that our operator remove these items for you but the operator cannot be held responsible for any damage that may be caused, as sometimes these items have been in place for many years and can be difficult to remove. Please note that we are not responsible for the re-installation of any removed fittings. Also it is extremely helpful to remove any non fitted bathroom furniture, all toiletries and personal items from the bathroom.'
        },
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }),
    created() {

    },
    watch: {

    },
    methods: {

    }
  }
</script>